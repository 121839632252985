
 import { defineComponent, computed } from "vue";
 import { useStore } from "vuex";
import { ref } from "vue";
import ApiService from "@/core/services/ApiService";
import router from "@/router";
 
 export default defineComponent({
     name: "global-search",
     components: {},
     setup() {
         const store = useStore();
         const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));
 
       const user = computed(() => {
             return store.getters.currentUser;
         });

        const search = ref('');
        const results = ref([]);
        const responseTime = ref(0);
        // parse the query string
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const q = urlParams.get('q');

        const goTo = (type, resource) => {
            if(type == 'machine') {
                 router.push({ name: 'training-machines-show', params: { machine_uuid: resource?.id } });
            }

            if(type == 'user') {
                router.push({ name: 'profile-user', params: { id: resource?.id } });
            }

            if(type == 'lesson') {
                
                router.push({ name: 'training-module-show-lesson', params: { lesson_id: resource?.id, id: resource?.module_id } });
            }

            if(type == 'challenge') {
                router.push({ name: 'training-module-show-lesson', params: { lesson_id: resource?.lesson?.id, id: resource?.lesson?.module_id } });
            }

            if(type == 'path'){
                router.push({ name: 'learning-paths-show', params: { id: resource?.id } });
            }
        }

        const doPreSearch = (content) => {
            search.value = content;
            doSerach(new Event('submit'));
        }

        const doSerach = (e) => {
            e.preventDefault();
            if(search.value.length < 3) return;
            console.log('searching: ' + search.value);
            // calculate the time it takes to get the response
            // set current Query String to the search value
            window.history.pushState({}, "", '/apps/global-search?q=' + search.value);
            const start = new Date().getTime();
            ApiService.get('global-search?q=' + search.value ).then((response) => {
                console.log(response);
                const end = new Date().getTime();
                responseTime.value = end - start;
                results.value = response.data;
                
            }).catch((error) => {
                console.log(error);
            });
        }
        if(q) {
            search.value = q;
            doSerach(new Event('submit'));
        }
         

     return {
         user,
         store,
         currentLanguage,
            search,
            doSerach,
            results,
            responseTime,
            goTo,
            doPreSearch
     };
     },
     methods: {
       
     }
 });
 